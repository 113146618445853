import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import headerBg from 'images/element/team/header-bg.png';
import headerText from 'images/element/team/header-text.svg';
import team1 from 'images/element/team/team-1.png';
import team2 from 'images/element/team/team-2.png';
import team3 from 'images/element/team/team-3.png';
import team4 from 'images/element/team/team-4.png';
import team5 from 'images/element/team/team-5.png';
import team6 from 'images/element/team/team-6.png';
import team7 from 'images/element/team/team-7.png';
import team8 from 'images/element/team/team-8.png';
import team9 from 'images/element/team/team-9.png';
import team10 from 'images/element/team/team-10.png';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Dimension from 'components/dimension';

const teamData = [
  {
    id: 't-01',
    name: 'Nelada Chanhirankul',
    img: team1,
    position: 'CEO',
  },
  {
    id: 't-02',
    name: 'Apiwat Supa',
    img: team2,
    position: 'Financial Officer',
  },
  {
    id: 't-03',
    name: 'Banjamas Sombatsri',
    img: team3,
    position: 'Financial Officer',
  },
  {
    id: 't-04',
    name: 'Chitra Chankrajang',
    img: team4,
    position: 'Financial Officer',
  },
  {
    id: 't-05',
    name: 'Patinya Ngowhuad',
    img: team5,
    position: 'Financial Officer',
  },
  {
    id: 't-06',
    name: 'Pattaramon Krungkraijak',
    img: team6,
    position: 'Financial Officer',
  },
  {
    id: 't-07',
    name: 'Phensuphang Dontuam',
    img: team7,
    position: 'Financial Officer',
  },
  {
    id: 't-08',
    name: 'Pornnapas Chairatnanthakul',
    img: team8,
    position: 'Financial Officer',
  },
  {
    id: 't-09',
    name: 'Thanaphiphat Phiphitthanathat',
    img: team9,
    position: 'Financial Officer',
  },
  {
    id: 't-10',
    name: 'Thapanun Sangtipkanya',
    img: team10,
    position: 'Financial Officer',
  },
];

const SocialIcon = ({ iconName }) => {
  return (
    <div
      className="flex items-center justify-center w-8 h-8 p-2 rounded-full"
      style={{ border: '1px solid #00464F', margin: '0 2px' }}
    >
      <FontAwesomeIcon
        className="inline-block text-sm text-gray-700"
        icon={['fab', iconName]}
      />
    </div>
  );
};

const Team = () => {
  return (
    <Layout
      renderContent={() => {
        return (
          <>
            <SEO title="Team" />
            <div
              className="flex flex-col w-full bg-white max-w-screen-xl"
              style={{
                marginTop: Dimension.NAVBAR_HEIGHT,
              }}
            >
              <div className="relative">
                <img
                  src={headerBg}
                  alt="header background"
                  className="object-cover w-auto h-56 lg:w-full lg:h-auto"
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      src={headerText}
                      alt="Meet our expert team"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full bg-white">
                <div className="py-10 grid grid-cols-1 gap-8 max-w-screen-lg md:grid-cols-2 lg:grid-cols-3">
                  {teamData.map(team => {
                    return (
                      <div
                        key={team.id}
                        className="flex flex-col items-center justify-center"
                      >
                        <img
                          src={team.img}
                          alt="team"
                          className="object-cover w-3/4 h-auto"
                        />
                        <div
                          className="pt-4 font-serif text-sm"
                          style={{ color: '#00464F' }}
                        >
                          {team.position}
                        </div>
                        <div
                          className="uppercase pt-2"
                          style={{ color: '#00464F' }}
                        >
                          {team.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};
export default Team;

/*
  <div className="flex items-center justify-center my-4">
    <SocialIcon iconName="facebook-f" />
    <SocialIcon iconName="twitter" />
    <SocialIcon iconName="linkedin-in" />
    <SocialIcon iconName="instagram" />
  </div>
*/
